
export const extractGroupsFromProductsList = (produtos = []) => {
    produtos.sort((a, b) =>
        (a.grupo.ordem > b.grupo.ordem) ? 1 : ((b.grupo.ordem > a.grupo.ordem) ? -1 : 0))

    const grupos = [];

    produtos.forEach(p => {
        // if (p.hasOwnProperty('produtos')) {
        //     p.combo_id = JSON.parse(JSON.stringify(p.id));
        //     p.produto_id = null;
        // } else {
        //     p.combo_id = null;
        //     p.produto_id = JSON.parse(JSON.stringify(p.id));
        // }

        p.produto_id = JSON.parse(JSON.stringify(p.id));

        p.id = null;
        p.quantidade = 0;

        grupos.findIndex(o => o.id == p.grupo?.id) === -1
            && grupos.push(p.grupo);
    });

    return grupos;
};
