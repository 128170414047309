export class Queue {
  list = [];
  executing = false;
  lastSend = 0;

  queueWait = 15000;

  // constructor(call) {
  //   this.callback = call;
  // }

  push(msg) {
    const uuid = (new Date).getTime();
    this.list.push({ uuid, ...msg });
    this.notify();
    return uuid;
  }

  async notify() {
    if (this.executing) {
      return;
    }
    this.executing = true;
    let v;
    // eslint-disable-next-line no-cond-assign
    while (v = this.list.shift()) {
      const now = Date.now();
      if (now - this.lastSend < this.queueWait) {
        await sleep(this.queueWait - (now - this.lastSend));
      }

      document.dispatchEvent(new CustomEvent('auto-print', { detail: v.id }));

      // await this.callback(v);
      this.lastSend = Date.now();
    }
    this.executing = false;
  }
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
