import store from '../store/index';

export class SoundAlert {
  soundLoop = null;
  intervalTime = 6000;
  sound = new Audio(require('@/assets/sounds/sino_with_interval_5s.mp3'));
  volume = localStorage.getItem('volumeSino');

  constructor() {
    this.sound.volume = typeof this.volume === 'string'
      ? +this.volume / 100
      : 1;
  }

  play() {
    this.pause();

    store.state.configuracoes.confirmar_pedidos_automaticamente
      ? this.sound.play()
      : (this.soundLoop = setInterval(() => this.sound.play(), this.intervalTime));

  }

  pause() {
    this.sound.pause();
    clearInterval(this.soundLoop);
    this.sound.currentTime = 0;
  }
}
