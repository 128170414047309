/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}sw.js`, {
    ready() {
      // Service worker is active
    },
    registered() {
      // Service worker has been registered
    },
    cached() {
      // Content has been cached for offline use
    },
    updatefound() {
      // Update found service worker
    },
    updated(registration) {
      // Updated service worker
      const newWorker = registration.waiting || registration.installing;

      if (newWorker) {
        // confirm('Novidades no ar, atualizar agora?') && newWorker.postMessage({ action: 'skipWaiting' })
        newWorker.postMessage({ action: 'skipWaiting' });
      }

      // newWorker && window.$snackbar && window.$snackbar.show({
      //   title: 'Novo conteúdo disponível',
      //   duration: 30000,
      //   action: {
      //     title: 'Atualizar',
      //     onPress: () => newWorker.postMessage({ action: 'skipWaiting' }),
      //   },
      // });
    },
    offline() {
      // No internet connection found. App is running in offline mode
    },
    error(error) {
      // Error during service worker registration
    },
  });

  // The event listener that is fired when the service worker updates
  // Here we reload the page
  if (navigator.serviceWorker) {
    let refreshing;
    navigator.serviceWorker.addEventListener('controllerchange', function () {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  }
}

let deferredPrompt;
document.addEventListener('click-install-pwa', () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      // if (choiceResult.outcome === 'accepted') {
        // console.log('Usuário aceitou o prompt de instalação');
      // }
      deferredPrompt = null;
    });
  }
});

window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault();
  deferredPrompt = event;
  document.dispatchEvent(new Event('menu-install-pwa'));
});

// const channel = new BroadcastChannel('sw');
// channel.addEventListener('message', event => {
//   console.log('Recebido do service-worker', event.data);
// });