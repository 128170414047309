import Vue from 'vue';
import App from './App';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import DatetimePicker from 'vuetify-datetime-picker';
import VueTheMask from 'vue-the-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment-timezone';

moment.locale('pt-br');

Vue.config.productionTip = false;

Vue.use(DatetimePicker);

Vue.use(VueTheMask);

Vue.use(vuetify, { iconfont: 'mdi' });

Vue.mixin({
    methods: {
        notify(text, type = 'success') {
            setTimeout(() => this.$root.$emit('notify', {text, type}), 100);
        },

        formatPrice(value = 0) {
            let val = (+value).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },

        formatDate(dh, format = 'DD/MM/YYYY HH:mm') {
            return dh ? this.moment(dh).format(format) : '';
        },
        
        timeAgo(time) {
            const now = this.moment();
            const diffInMinutes = now.diff(this.moment(time), 'minutes');
            
            if (diffInMinutes < 60) {
                return `há ${diffInMinutes}m`; 
            }

            const diffInHours = now.diff(this.moment(time), 'hours');
            if (diffInHours < 24) {
                return `há ${diffInHours}h`; 
            }

            const diffInDays = now.diff(this.moment(time), 'days');
            if (diffInDays < 7) {
                return `há ${diffInDays}d`; 
            }

            return this.moment(time).format('DD/MM/YYYY'); 
        },
    },
});

// (window.location.hostname == 'localhost')
const baseURL = (document.URL.indexOf('localhost') !== -1)
    ? 'http://localhost:8000/api'
    : 'https://webservice.zapermenu.com.br/api';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;

const http = axios.create({ baseURL });

const gerarPdf = async (params) => {
    const headers = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        responseType: 'blob',
    };
    const { data } = await http.post('report/gerar-pdf', params, headers);

    if (data?.type === 'warning') {
        return data;
    }

    const timestamp = Date.now();
    const url = window.URL.createObjectURL(new Blob([data]));

    let link = null;
    if (!document.getElementById('report-download')) {
        link = document.createElement('a');
        link.id = 'report-download';
        document.body.appendChild(link);
    } else {
        link = document.querySelector('#report-download');
    }

    link.href = url;
    link.setAttribute('download', `relatório-${timestamp}.pdf`);

    link.click();
}

Vue.prototype.$http = http;
Vue.prototype.moment = moment;
Vue.prototype.$urlAPI = baseURL;
Vue.prototype.$gerarPdf = gerarPdf;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
